import { css } from '@emotion/css';
import Alarm, { useAlarmsApi } from '../Alarm';

export default function AlarmExample() {
  const { useGetAlarms } = useAlarmsApi();
  const { data: alarms } = useGetAlarms(true);

  return (
    <div className={cssAlarmExample}>
      <h3>Alarm</h3>
      <Alarm />
      {JSON.stringify(alarms, null, 2)}
    </div>
  );
}

const cssAlarmExample = css`
  padding: 20px;
  code {
    margin-top: 20px;
    white-space: break-spaces;
  }
`;
