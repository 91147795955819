import { Box } from '@mui/material';
import { workerType } from './../../common/config/Code';
import { css } from '@emotion/react';
import { Color } from '@src/common/styles/color';
import {
  IconArrowDownOnUrl,
  IconArrowDownOffUrl,
  IconMoreUrl,
  IconArrowRightBlackUrl,
  IconArrowRightGrayUrl,
  IconOutsourcingOffUrl,
  IconOutsourcingOnUrl
} from '@src/resources/icon';
export const JobAssignStyle = css`
  width: 756px;
  max-height: calc(100 - 100px);
  text-align: center;
  color: #242424;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 50px 20px 30px;

  .button.close.circle {
    top: 6px;
    right: 12px;
    position: absolute;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.25px;
    text-align: left;
    text-align: center;
    margin-bottom: 64px;
  }

  .disable {
    color: #e6e5ef;
    border-color: #e6e5ef;
    :hover {
      cursor: default;
    }
  }

  .cancel--assign--container {
    display: flex;
    gap: 12px;
    margin-top: 28px;
    height: 42px;
  }

  .title--description--box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
    padding: 10px 16px;
    background-color: rgba(250, 250, 250, 1);
    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      > span {
        display: flex;
        align-items: center;
        gap: 6px;
        color: rgba(114, 114, 114, 1);
      }
      .task--key {
        position: relative;
        margin-right: 10px;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -10px;
          transform: translateX(-50%);
          width: 2px;
          height: 2px;
          background-color: #cbcbcb;
          border-radius: 100%;
        }
      }
      .task--language {
        font-weight: 600;
        .arrow {
          margin: 0 8px;
        }
      }
    }
    > p {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #727272;
    }
  }
  .task--split {
    display: flex;
    justify-content: center;
    button {
      padding-right: 20px;
      color: rgba(114, 114, 114, 1);
      background: url(${IconMoreUrl}) no-repeat center right;
    }
  }
  .all--count {
    position: relative;
    margin-bottom: 40px;
    padding: 0 16px;
    text-align: left;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      height: 1px;
      background-color: #ececec;
    }
    .count-title {
      margin-bottom: 2px;
      font-weight: 400;
    }
    > div {
      display: flex;
      gap: 5px;
      p {
        display: flex;
        position: relative;
        span {
          margin-right: 4px;
          color: #727272;
        }
      }
    }
    > .total--time {
      span {
        margin-right: 0px;
        margin-left: 3px;
      }
    }
    > .total--amount {
      gap: 10px;
      p {
        &:first-of-type::after {
          content: '';
          position: absolute;
          top: 10px;
          right: -7px;
          width: 2px;
          height: 2px;
          background-color: #cbcbcb;
          border-radius: 100%;
        }
      }
    }
  }
  .detail--task--wrap {
    margin-bottom: 20px;
    .top--box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      > p {
        color: rgba(114, 114, 114, 1);
        > span {
          margin: 0 4px;
          font-weight: 700;
          color: rgba(230, 49, 26, 1);
        }
      }
      .reset--button {
        margin-left: auto;
        width: 66px;
        height: 32px;
        border: 1px solid rgba(203, 203, 203, 1);
        border-radius: 2px;
        box-sizing: border-box;
      }
    }
    .taskWrap {
      max-height: 400px;
      overflow-y: auto;
    }
    .task--list {
      position: relative;
      margin-bottom: 8px;
      padding: 10px 16px 16px;
      background-color: rgba(250, 250, 250, 1);
      .task--title {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 600;
        > button {
          width: 18px;
          height: 18px;
        }
      }
      .sub--title--box {
        margin: 16px 0;
        text-align: left;
      }
      .sub--title {
        display: flex;
        align-items: center;
        font-weight: 400;
        .required {
          margin-left: 4px;
          color: rgba(230, 49, 26, 1);
        }
        .task--amount {
          margin-left: 8px;
          padding: 0 6px;
          height: 23px;
          line-height: 23px;
          font-size: 12px;
          color: rgba(114, 114, 114, 1);
          background-color: rgba(245, 245, 245, 1);
          border-radius: 2px;
        }
      }
      .worker--list {
        display: flex;
        gap: 15px;
        padding-bottom: 10px;
        > .worker--item {
          width: 33.3333%;
          &.selected {
            > div {
              transition: all ease 0.2s;
              background-color: rgba(155, 81, 224, 0.06);
            }
          }
          > div {
            background-color: rgba(250, 250, 250, 1);
          }
          .user--box {
            display: flex;
            align-items: center;
            gap: 4px;
            position: relative;
            /* height: 40px; */
            &.workerStatus {
              .worker-name {
                cursor: pointer;
                transition: all ease 0.2s;
                &:hover {
                  background-color: rgba(36, 36, 36, 1);
                  color: #fff;
                  .arrow {
                    background: url(${IconArrowDownOnUrl}) no-repeat center;
                  }
                }
              }
            }

            .img--box {
              width: 30px;
              height: 30px;
              border-radius: 100%;
              overflow: hidden;
              box-sizing: border-box;
              &.img--none {
                background-color: #cbcbcb;
                border: 1px solid #727272;
              }
              img {
                width: fit-content;
              }
            }
            > div {
              display: flex;
              flex-direction: column;
              max-width: calc(100% - 35px);
              .worker-outsourcing {
                margin-bottom: 2px;
                height: 13px;
                line-height: 13px;
                text-align: left;
                input {
                  display: none;
                  &:checked {
                    & + label {
                      color: #9b51e0;
                      background: rgba(155, 81, 224, 0.06)
                        url(${IconOutsourcingOnUrl}) no-repeat left 2px center;
                    }
                  }
                }
                label {
                  display: inline-block;
                  padding: 0 3px 0 10px;
                  height: 13px;
                  font-size: 10px;
                  color: #aaaaaa;
                  background: #ececec url(${IconOutsourcingOffUrl}) no-repeat
                    left 2px center;
                  border-radius: 2px;
                  cursor: pointer;
                }
              }
              .worker-name {
                display: inline-block;
                position: relative;
                padding-right: 20px;
                &:hover {
                  .worker-control {
                    display: block;
                    li {
                      &:hover {
                        background-color: #ececec;
                      }
                    }
                  }
                }
                .arrow {
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  width: 10px;
                  height: 6px;
                  text-indent: 9999px;
                  background: url(${IconArrowDownOffUrl}) no-repeat center;
                  overflow: hidden;
                }
                .worker-control {
                  display: none;
                  position: absolute;
                  top: 100%;
                  left: 0%;
                  width: 84px;
                  padding-top: 4px;
                  z-index: 1;
                  > ul {
                    padding: 4px 0;
                    background-color: #fff;
                    border: 1px solid rgba(236, 236, 236, 1);
                    border-radius: 2px;
                    > li {
                      padding: 4px 14px;
                      color: #242424;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
          .worker--box {
            display: flex;
            align-items: center;
            gap: 4px;
            position: relative;
            height: 40px;
            &:hover {
              /* background-color: rgba(155, 81, 224, 0.06); */
              cursor: pointer;
              .worker-control {
                display: block;
              }
            }
            .worker-name {
              color: rgba(114, 114, 114, 1);
            }
            .arrow--right {
              position: relative;
              top: -1px;
              margin-left: 6px;
              width: 10px;
              height: 6px;
              text-indent: 9999px;
              background: url(${IconArrowRightGrayUrl}) no-repeat center;
              overflow: hidden;
            }
          }
        }
      }
      .task--time--box {
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding-top: 3px;
        input {
          width: 45px;
          height: 35px;
          border: 1px solid #ececec;
          text-align: center;
          &:read-only {
            color: #9a9cb5;
          }
          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        .MuiFormControl-root .MuiInputBase-root {
          width: 45px !important;
          height: 35px !important;
          svg {
            right: 5px !important;
          }
        }
      }
      .memo--box {
        position: relative;
        padding-top: 2px;
        > input {
          padding-left: 10px;
          width: 100%;
          height: 40px;
          border: 1px solid rgba(236, 236, 236, 1);
          border-radius: 2px;
          transition: all ease 0.2s;
          &::placeholder {
            font-weight: 400;
          }
          &.warning {
            border: 1px solid rgba(230, 49, 26, 1);
          }
        }
        > p {
          position: absolute;
          top: -20px;
          right: 0%;
          font-size: 12px;
          color: rgba(170, 170, 170, 1);
          > span {
            color: rgba(114, 114, 114, 1);
            &.warning {
              color: rgba(230, 49, 26, 1);
            }
          }
        }
      }
      .list--close {
        position: absolute;
        top: 10px;
        right: 15px;
      }
    }
  }
`;

export const alertWrap = css`
  box-sizing: border-box;
  position: absolute;
  background: #fff;
  min-width: 420px;
  //max-width: 420px;
  max-height: calc(100% - 30px);
  border-radius: 2px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  padding: 50px 20px 30px;
  text-align: center;
`;
