import { createBrowserRouter } from 'react-router-dom';

import Layout from '@layouts/Layout';
import ModalLayout from '@layouts/ModalLayout';
import AuthPage from '@pages/Auth.page';
import CustomerPage from '@pages/dashboard/Customer.page';
import LanguagesPage from '@pages/dashboard/Languages.page';
import WordLanguagePage from '@pages/dashboard/WordLanguage.page';
import WordWorkerPage from '@pages/dashboard/WordWorker.page';
import WorkerPage from '@pages/dashboard/Worker.page';
import ErrorBoundary from '@pages/errors/ErrorBoundary';
import LoginPage from '@pages/login/Login.page';
import MainPage from '@pages/main/Main.page';
import JobAssignPage from '@pages/modal/JobAssign.page';
import JobAssignNewPage from '@pages/modal/JobAssignNew.page';
import JobDetailPage from '@pages/modal/JobDetail.page';
import JobDetailTasker from '@pages/modal/JobDetailTasker.page';
import JobRegisterPage from '@pages/modal/JobRegister.page';
import { LLAssignPage } from '@pages/modal/LLAssign.page';
import { PMAssignPage } from '@pages/modal/PMAssign.page';
import AssignAlert from '@pages/modal/AssignAlert.page';
import MyTaskPage from '@pages/myTask/MyTask.page';
import ProjectPage from '@pages/project/Project.page';
import TaskPage from '@pages/task/Task.page';
import { isDev } from '@src/common/config/Code';
import DashboardPage from '@src/pages/dashboard/Dashboard.page';
import AlarmExample from '@src/pages/examples/AlarmExample';
import JobDetailMyWork from '@src/pages/modal/JobDetailMyWork.page';
import MyTaskTabPage from '@src/pages/myTask/MyTaskTab.page';
import PrivacyPage from '@src/pages/policy/Privacy.page';
import ServicePage from '@src/pages/policy/Service.page';
import TaskTabPage from '@src/pages/task/TaskTab.page';
import TaskAllPage from '@src/pages/task/all/TaskAll.page';
import TaskManagerPage from '@src/pages/taskManagement/TaskManager.page';
import FileUploadPage from '../modal/FileUpload.page';
import TaskCreatePage from '../modal/TaskCreate.page';
import TaskDownloadPage from '../modal/TaskDownloadPage';
import TaskSplitPage from '../modal/TaskSplit.page';
import PartPerformancePage from '@src/pages/dashboard/PartPerformance.page';
import WorkerPerformancePage from '@src/pages/dashboard/WorkerPerformance.page';
import WorkerResourcePage from '@src/pages/dashboard/WorkerResource.page';
import WorkRetentionPage from '@src/pages/dashboard/WorkRetention.page';

/**
 * RouteObject Definition
 */
export const useRouteObject = () => {
  const routes = createBrowserRouter([
    {
      path: '/',
      element: <AuthPage />,
      children: [
        { path: '/policy/privacy', element: <PrivacyPage /> },
        { path: '/policy/service', element: <ServicePage /> },
        { path: '/login', element: <LoginPage /> },
        { path: '/login/privacy', element: <PrivacyPage /> },
        { path: '/login/:type', element: <LoginPage /> },
        {
          path: '/',
          element: <Layout />,
          children: [
            {
              path: 'project',
              element: <MainPage />,
              children: [
                {
                  path: 'pmAssign',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':id',
                      element: <PMAssignPage />
                    }
                  ]
                },
                {
                  path: 'llAssign',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':id',
                      element: <LLAssignPage />
                    }
                  ]
                }
              ]
            },
            {
              path: 'project/:id',
              element: <ProjectPage />,
              children: [
                {
                  path: 'register',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobRegisterPage />
                    }
                  ]
                },
                {
                  path: 'detail',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobDetailPage />
                    }
                  ]
                },
                {
                  path: 'copy',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobRegisterPage />
                    }
                  ]
                },
                {
                  path: 'modify',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobRegisterPage />
                    }
                  ]
                },
                {
                  path: 'assignAlert',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':assignId',
                      element: <AssignAlert />
                    }
                  ]
                },
                {
                  path: 'assignWorker',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobAssignNewPage />
                    }
                  ]
                }
              ]
            },
            {
              path: 'task',
              element: <TaskPage />,
              children: [
                {
                  path: 'taskDetail',
                  element: <ModalLayout />,
                  children: [{ path: ':id', element: <JobDetailTasker /> }]
                },
                {
                  path: 'assign',
                  element: <ModalLayout />,
                  children: [{ path: ':id', element: <JobAssignPage /> }]
                }
              ]
            },
            {
              path: 'tasktab',
              element: <TaskTabPage />,
              children: [
                {
                  path: 'detail',
                  element: <ModalLayout />,
                  children: [{ path: ':id', element: <JobDetailTasker /> }]
                },
                {
                  path: 'assign',
                  element: <ModalLayout />,
                  children: [{ path: ':id', element: <JobAssignNewPage /> }]
                }
              ]
            },
            {
              path: 'mytask',
              element: <MyTaskPage />,
              children: [
                {
                  path: 'mytaskDetail',
                  element: <ModalLayout />,
                  children: [{ path: ':id', element: <JobDetailMyWork /> }]
                }
              ]
            },
            {
              path: 'mytasktab',
              element: <MyTaskTabPage />,
              children: [
                {
                  path: 'detail',
                  element: <ModalLayout />,
                  children: [{ path: ':id', element: <JobDetailTasker /> }]
                }
              ]
            },
            {
              path: 'taskManager',
              element: <TaskManagerPage />,
              children: [
                {
                  path: 'upload',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <FileUploadPage />
                    }
                  ]
                },
                {
                  path: 'reUpload',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <FileUploadPage />
                    }
                  ]
                },
                {
                  path: 'create',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <TaskCreatePage />
                    }
                  ]
                },
                {
                  path: 'split',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <TaskSplitPage />
                    }
                  ]
                },
                {
                  path: 'download',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':languageTaskId',
                      element: <TaskDownloadPage />
                    }
                  ]
                }
              ]
            },
            {
              path: 'task/all',
              element: <TaskAllPage />
            },
            {
              path: 'dashboard',
              element: <DashboardPage />,
              children: [
                {
                  path: ':id/detail',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobDetailPage />
                    }
                  ]
                },
                {
                  path: ':id/copy',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobRegisterPage />
                    }
                  ]
                },
                {
                  path: ':id/modify',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobRegisterPage />
                    }
                  ]
                },
                {
                  path: 'assignWorker',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':jobDetailId',
                      element: <JobAssignNewPage />
                    }
                  ]
                },
                {
                  path: ':assignAlert',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':assignId',
                      element: <AssignAlert />
                    }
                  ]
                }
              ]
            },
            {
              path: 'dashboard/customer',
              element: <CustomerPage />
            },
            {
              path: 'dashboard/worker',
              element: <WorkerPage />
            },
            {
              path: 'dashboard/languages',
              element: <LanguagesPage />
            },
            {
              path: 'dashboard/wordworker',
              element: <WordWorkerPage />
            },
            {
              path: 'dashboard/wordlanguage',
              element: <WordLanguagePage />
            },
            {
              path: 'dashboard/workerresource',
              element: <WorkerResourcePage />
            },
            {
              path: 'dashboard/workerperformance',
              element: <WorkerPerformancePage />
            },
            {
              path: 'dashboard/partperformance',
              element: <PartPerformancePage />
            },
            {
              path: 'dashboard/workretention',
              element: <WorkRetentionPage />
            }
            // {
            //   path: 'dashboard/workerperformance',
            //   element: <WorkerPerformancePage />
            // },
            // {
            //   path: 'dashboard/workerresource',
            //   element: <WorkerResourcePage />
            // },
            // {
            //   path: 'dashboard/workerretention',
            //   element: <WorkerRetentionPage />
            // }
          ]
        }
      ],
      errorElement: <ErrorBoundary />
    },
    {
      path: '/alarm',
      element: isDev ? <AlarmExample /> : <></>
    },
    {
      path: '/jobRegister',
      element: isDev ? <JobRegisterPage /> : <></>
    }
  ]);

  return { routes };
};
