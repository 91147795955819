import { LanguageCodeType } from '@common/config/Code';
import { useHookFunc } from '@hooks/utils';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { IDashboardFilterData, ExposureType } from '../types';
import { CleaningServices } from '@mui/icons-material';

function compare(a1: any[], a2: any[]): boolean {
  a1 = [...a1];
  a2 = [...a2];
  const rs =
    a1
      .filter((e) => e !== 'ALL' && e !== '0')
      .sort()
      .join() ===
    a2
      .filter((e) => e !== 'ALL' && e !== '0')
      .sort()
      .join();
  return rs;
}

export function useDashboardFilter(filterData: any) {
  const { arraySet } = useHookFunc();

  const initialDataMap: IDashboardFilterData = {
    keyword: '',
    taskStatus: ['NEW', 'COMPLETE_ASSIGN', 'PROGRESS', 'COMPLETE'],
    taskType: ['ALL'],
    startLanguageList: ['ALL'],
    destinationLanguageList: ['ALL'],
    projectManagerUserIdList: ['0'],
    workUserIdList: ['0'],
    projectIdList: ['ALL'],
    exposure: '',
    isFilter: false
  };

  const [tempKeyword, setTempKeyword] = useState<string>('');

  const [dashboardFilter, setDashboardFilter] = useState<IDashboardFilterData>(
    () => ({ ...initialDataMap, ...filterData?.dashboardFilter })
  );

  const dashboardFilterProxy = {
    handleChangeKeyword: (e: any) => {
      const { value } = e.target;
      setTempKeyword(value);
    },
    setKeyword: () => {
      setDashboardFilter((state) => ({
        ...state,
        keyword: tempKeyword
      }));
    },

    setTaskStatus: (value: string) => {
      const d =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.taskStatus, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        taskStatus: d.length ? d : ['ALL']
      }));
    },
    setTaskType: (value: string) => {
      const filterTaskType =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.taskType, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        taskType: filterTaskType.length ? filterTaskType : ['ALL']
      }));
    },

    setProjectList: (value: string) => {
      if (value === 'ALL') {
        // "전체" 선택 시 특별한 처리
        setDashboardFilter((state) => ({
          ...state,
          projectIdList: ['ALL'] // 'ALL'을 사용하여 모든 프로젝트가 선택된 것으로 처리
        }));
      } else {
        // const projectList =
        //   value === '0'
        //     ? ['0']
        //     : _.filter(
        //         arraySet(dashboardFilter.projectIdList, value),
        //         (item) => item !== '0'
        //       );
        // setDashboardFilter((state) => ({
        //   ...state,
        //   projectIdList: projectList.length ? projectList : ['0']
        // }));
        const projectList = _.filter(
          arraySet(dashboardFilter.projectIdList, value),
          (item) => item !== '0' && item !== 'ALL'
        );
        setDashboardFilter((state) => ({
          ...state,
          projectIdList: projectList.length ? projectList : ['0']
        }));
      }
    },
    setStartLanguageList: (value: string) => {
      const startLanguageList =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.startLanguageList, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        startLanguageList: startLanguageList.length
          ? (startLanguageList as LanguageCodeType[])
          : ['ALL']
      }));
    },
    setDestinationLanguageList: (value: string) => {
      const destinationLanguageList =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.destinationLanguageList, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        destinationLanguageList: destinationLanguageList.length
          ? (destinationLanguageList as LanguageCodeType[])
          : ['ALL']
      }));
    },
    setProjectManagerUserIdList: (value: string) => {
      const projectManagerUserIdList =
        value === '0'
          ? ['0']
          : _.filter(
              arraySet(dashboardFilter.projectManagerUserIdList, value),
              (item) => item !== '0'
            );
      setDashboardFilter((state) => ({
        ...state,
        projectManagerUserIdList: projectManagerUserIdList.length
          ? projectManagerUserIdList
          : ['0']
      }));
    },
    setWorkUserIdList: (value: string) => {
      const workUserIdList =
        value === '0'
          ? ['0']
          : _.filter(
              arraySet(dashboardFilter.workUserIdList, value),
              (item) => item !== '0'
            );
      setDashboardFilter((state) => ({
        ...state,
        workUserIdList: workUserIdList.length ? workUserIdList : ['0']
      }));
    },
    setExposure: (value: ExposureType) => {
      setDashboardFilter((state) => ({
        ...state,
        exposure: value
      }));
    },
    setIsFilter: (value: boolean) => {
      setDashboardFilter((state) => ({
        ...state,
        isFilter: value
      }));
    },
    clearFilter: () => {
      setTempKeyword('');
      setDashboardFilter(() => ({
        ...initialDataMap
      }));
    }
  };
  //#endregion
  useEffect(() => {
    if (filterData) {
      setTempKeyword(filterData?.dashboardFilter?.keyword ?? '');
      setDashboardFilter((state) => ({
        ...state,
        ...filterData?.dashboardFilter
      }));
    }
  }, [filterData]);

  const isFilter = useMemo(() => {
    return !(
      compare(initialDataMap.taskStatus, dashboardFilter.taskStatus) &&
      compare(initialDataMap.taskType, dashboardFilter.taskType) &&
      compare(
        initialDataMap.startLanguageList,
        dashboardFilter.startLanguageList
      ) &&
      compare(
        initialDataMap.destinationLanguageList,
        dashboardFilter.destinationLanguageList
      ) &&
      compare(
        initialDataMap.projectManagerUserIdList,
        dashboardFilter.projectManagerUserIdList
      ) &&
      compare(initialDataMap.workUserIdList, dashboardFilter.workUserIdList) &&
      compare(initialDataMap.projectIdList, dashboardFilter.projectIdList) &&
      dashboardFilter.keyword === ''
    );
  }, [dashboardFilter]);

  return {
    tempKeyword,
    setTempKeyword,
    dashboardFilter,
    setDashboardFilter,
    dashboardFilterProxy,
    isFilter
  };
}
