import React, { Fragment, forwardRef, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { alertWrap } from '@hooks/modal/Modal.style';
import { JobDetailStyle } from './JobDetailTasker.style';
import {
  getStatus as getTaskStatus,
  compireAmountType
} from '@src/common/config/Code';
import { useGetTaskDetail } from '@src/hooks/languageTask/get';
import _ from 'lodash';
import { usePageFunc } from '@src/hooks/utils';
import {
  ILanguageSubTaskList,
  ILanguageTaskData
} from '@src/common/config/ILanguageTask';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { useToast } from '@src/hooks/modal/useToast';
import {
  renderDateTimeNoWrap,
  renderLanguageByCode,
  renderRowStatus,
  renderTaskType
} from '../dashboard/utils/render';
import { cx } from '@emotion/css';
import { Arrow } from '../../units/sobra/Arrow';
import { Worker } from '../../units/sobra/Worker';
import {
  IconFileTitleTrue,
  IconFileTitleFalse,
  IconMessageOff,
  IconMessageOn
} from '@src/resources/icon';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';

/**
 * 작업 담당자 배정 상세정보 모달 페이지
 * route : /taskDetail/:id
 * @returns
 */
function JobDetailTasker(
  { close, setOpenAssignModal, ...props }: any,
  ref: any
) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();
  if (id === undefined) id = props?.id;
  const { detailData, error }: { detailData: ILanguageTaskData; error: any } =
    useGetTaskDetail(id ?? '');

  const { workerType } = usePageFunc();
  const { openHandle } = useToast();
  const { taskAmountRate, taskAmountText, getDateTime } = usePageFunc();

  const closeModal = () => {
    if (typeof close === 'function') {
      delete window.history.state.languageTaskId;
      close();
    } else {
      const regExp = /\/detail\/\d+$/;
      if (regExp.test(pathname)) {
        navigate(pathname.replace(regExp, ''));
      } else {
        navigate(-1);
      }
    }
  };

  const taskDetailAssignHandler = () => {
    if (
      typeof close === 'function' ||
      typeof setOpenAssignModal === 'function'
    ) {
      if (typeof close === 'function') close();
      if (typeof setOpenAssignModal === 'function') setOpenAssignModal(true);
    } else navigate(pathname.replace('/detail/', '/assign/'));
  };

  const activeValid = (worker: any) => {
    const { status, name } = worker;
    let label = name;
    if (status === 'INACTIVE') {
      label = `${name} (비활성화)`;
    } else if (status === 'LEAVE') {
      label = '삭제된 사용자';
    }
    return label;
  };

  useEffect(() => {
    if (error?.developerMessage === '403') {
      closeModal();
      // navigate('/errorRoles');
      openHandle({
        text:
          error?.clientMessage ??
          error.message ??
          '알 수 없는 오류가 발생했습니다.',
        severity: 'error'
      });
    }
  }, [error]);

  return (
    <div css={[JobDetailStyle, alertWrap]} ref={ref}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <div className="task-type">{renderTaskType(detailData.taskType)}</div>
      <div className="task-title">{detailData.taskTitle}</div>
      {detailData?.canAssignWorker && pathname.search('/mytasktab/') === -1 ? (
        <button
          className="assign-button"
          onClick={taskDetailAssignHandler}
          disabled={false}
        >
          작업자 배정
        </button>
      ) : null}

      <div className="simple-info">
        <div className="row">
          <div className="status">
            {renderRowStatus(detailData.languageTaskStatus)}
          </div>
          <div>{detailData.languageTaskKey}</div>
          <svg
            width="2"
            height="2"
            viewBox="0 0 2 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="1" r="1" fill="#AAAAAA" />
          </svg>

          <div className="languages">
            {renderLanguageByCode(detailData.startLanguage as string)}
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L5.24264 4.75736M1 1.5014L5.24264 5.74405"
                stroke="#242424"
                strokeWidth="1.4"
              />
            </svg>
            {renderLanguageByCode(detailData.destinationLanguage as string)}
          </div>
        </div>
        <div className="language-task-title">
          {detailData.fileRegisterStatus ? (
            <IconFileTitleTrue />
          ) : (
            <IconFileTitleFalse />
          )}
          {detailData.languageTaskTitle ?? ''}
        </div>
      </div>

      <div className="is-boracat">
        <div className="title">작업 환경</div>
        <div className="content">
          <div
            className={cx('option', { active: detailData.isBoracatUseOrNot })}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5224 3.46779L8.99018 2.94704L7.94868 2.01147L7.4809 2.53223L4.18465 6.20173L2.47938 4.59461L1.96996 4.11451L1.00977 5.13334L1.51918 5.61344L3.24882 7.24353L3.23777 7.25583L3.75212 7.71786L4.25562 8.19238L4.26697 8.18034L4.27927 8.19139L4.74139 7.67694L5.21582 7.17355L5.20378 7.1622L8.5224 3.46779Z"
                fill="#9B51E0"
              />
            </svg>
            보라캣
          </div>
          <div
            className={cx('option', { active: !detailData.isBoracatUseOrNot })}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5224 3.46779L8.99018 2.94704L7.94868 2.01147L7.4809 2.53223L4.18465 6.20173L2.47938 4.59461L1.96996 4.11451L1.00977 5.13334L1.51918 5.61344L3.24882 7.24353L3.23777 7.25583L3.75212 7.71786L4.25562 8.19238L4.26697 8.18034L4.27927 8.19139L4.74139 7.67694L5.21582 7.17355L5.20378 7.1622L8.5224 3.46779Z"
                fill="#9B51E0"
              />
            </svg>
            외부 CAT
          </div>
        </div>
      </div>

      <div className="amounts">
        <div className="header">
          <div className="left">
            <div className="title">총 분량</div>

            {detailData.taskType !== 'LQA' && (
              <div className="values">
                Gross <span className="val">{detailData.grossAmount}</span>
                <div className="dot" />
                <div>
                  Net <span className="val">{detailData.netAmount}</span>
                </div>
              </div>
            )}
          </div>

          {detailData.taskType === 'LQA' ? (
            <>(250단어 · 자 / 시간)</>
          ) : (
            <>
              {!detailData.isBoracatUseOrNot && (
                <div className="controls">
                  <div className="gross">
                    <span className="name tm">TM매치</span>
                    <span
                      className={`value ${detailData.tmMatch ? 'on' : 'off'}`}
                    >
                      {detailData.tmMatch ? 'ON' : 'OFF'}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {detailData.taskType === 'LQA' ? (
          <div className="time-calculation">
            {Math.floor((detailData?.grossAmount ?? 0) / 250)}
            시간
            {(detailData?.grossAmount ?? 0) % 250 > 0 ? '30' : '0'}분
          </div>
        ) : (
          <>
            {!detailData.isBoracatUseOrNot && (
              <div className="content">
                {Array.isArray(detailData?.languageTaskAmountList) &&
                  detailData.languageTaskAmountList
                    .sort((a, b) => {
                      return compireAmountType(a.matchingRate, b.matchingRate);
                    })
                    .map((e, i) => (
                      <div className="item" key={i}>
                        <div className="name">
                          {taskAmountText[e.matchingRate]}
                        </div>
                        <div className="value">{e.amount}</div>
                      </div>
                    ))}
              </div>
            )}
          </>
        )}
      </div>

      <div className="dates">
        <div className="content">
          <div className="item">
            <div className="name">시작일</div>
            <div className="value">
              {renderDateTimeNoWrap(detailData?.startDateTime, false)}
            </div>
          </div>
          <div className="item">
            <div className="name">마감일</div>
            <div className="value">
              {renderDateTimeNoWrap(
                detailData?.endDateTime,
                !['COMPLETE', 'DELIVERY_COMPLETE'].includes(
                  detailData?.languageTaskStatus ?? ''
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {detailData?.languageSubTaskList?.length ? (
        <div className="subtasks">
          <div className="title">
            <div className="left">작업 분량</div>
            <div className="right">
              {detailData.grossAmount !==
                _.reduce(
                  _.map(
                    detailData.languageSubTaskList,
                    (v: any) => v.taskAmount
                  ),
                  (sum, n) => sum + n,
                  0
                ) && (
                <div className="amountMismatch">
                  * 총 분량과 배분된 부작업의 총합이 불일치합니다.
                </div>
              )}
            </div>
          </div>
          {Array.isArray(detailData?.languageSubTaskList) &&
            detailData.languageSubTaskList.map(
              (e: ILanguageSubTaskList, i: number) => (
                <div className="amounts" key={i}>
                  <div className="amount">
                    {detailData.taskType === 'LQA'
                      ? `${Math.floor(e.taskAmount / 250)} 시간 
                    ${e.taskAmount % 250 > 0 ? '30' : '00'} 분`
                      : `${e.taskAmount.toLocaleString('en-US')}`}
                  </div>
                  <div className="message-tooltip">
                    {e.memo === '' || e.memo === null ? (
                      <IconMessageOff width={14} height={14} />
                    ) : (
                      e.memo && (
                        <SimpleTooltip message={e.memo}>
                          <IconMessageOn width={14} height={14} />
                        </SimpleTooltip>
                      )
                    )}
                  </div>
                  <div className="workers">
                    {Array.isArray(e.workerList) && (
                      <>
                        <Worker
                          worker={e.workerList.find(
                            (e) => e.workerType === 'TRANSLATOR'
                          )}
                          type="번역"
                        />
                        <Arrow />
                        <Worker
                          worker={e.workerList.find(
                            (e) => e.workerType === 'FIRST_CHECKER'
                          )}
                          type="1차 검수"
                        />
                        <Arrow />
                        <Worker
                          worker={e.workerList.find(
                            (e) => e.workerType === 'SECOND_CHECKER'
                          )}
                          type="2차 검수"
                        />
                      </>
                    )}
                  </div>
                </div>
              )
            )}
        </div>
      ) : null}

      <div className="description">
        <div className="title">설명</div>
        <div className="content">{detailData.description}</div>
      </div>

      <div className="links">
        <div className="title">링크</div>
        <div className="content">
          {Array.isArray(detailData.linkList) &&
            detailData.linkList.map((e, i) => (
              <a key={i} href={e} target="_blank" rel="noreferrer">
                {e}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default forwardRef(JobDetailTasker);
