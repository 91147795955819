import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IFilterWrap {
  fullWidth?: boolean;
  height?: string;
}
export const FilterWrap = styled.div<IFilterWrap>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : null)};
  height: ${({ height }) => (height ? height : '48px')};

  .dropdown {
    display: none;
  }
  :hover {
    .filter-input {
      color: #fff;
      background-color: #242424;
      cursor: pointer;
      .icon {
        fill: #fff;
      }
      .checked {
        background-color: #fff;
        color: #000;
      }
      .label {
        color: #fff;
      }
    }
    .dropdown {
      display: block;
    }
  }
  &.dimmed {
    opacity: 0.4;
    pointer-events: none;
  }
`;
export const FilterFileWrap = styled.div<IFilterWrap>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : null)};

  .default {
    display: none;
  }
  .dropdown {
    display: none;
  }
  :hover {
    .filter-input {
      cursor: pointer;
      .icon {
        fill: #fff;
      }
      .checked {
        background-color: #fff;
        color: #000;
      }
      .label {
        color: #fff;
      }
    }
    .dropdown {
      display: block;
    }
  }
`;
interface IFilterInput {
  unable?: boolean;
  fontSize?: string;
  optionLeftMargin?: string;
}

export const FilterFileInput = styled.div<IFilterInput>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: -0.25px;

  color: #000;

  .label--checked {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    white-space: nowrap;

    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: center;
    }
  }
  .label--selectOption {
    height: 100%;
    display: flex;
    align-items: center;
    .label {
      color: #2c3558;
    }
    .selectOption {
      margin-left: ${({ optionLeftMargin }) =>
        optionLeftMargin ? optionLeftMargin : '8px'};
      font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
      &.colorGray {
        color: #9a9cb5;
      }
    }
  }

  .icon {
    width: 30px;
    height: 20px;
  }
`;

export const FilterInput = styled.div<IFilterInput>`
  white-space: nowrap;
  border-radius: 2px;
  padding: 5px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: -0.25px;

  background-color: #fff;
  color: #000;

  &.filter--active {
    .checked {
      background-color: #000;
      color: #fff;
      width: 15px;
      height: 15px;
      border-radius: 30px;
      text-align: center;

      font-size: 10px;
      font-weight: 700;
      line-height: 13px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .label--checked {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    white-space: nowrap;

    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: center;
    }
  }
  .label--selectOption {
    height: 100%;
    display: flex;
    align-items: center;
    .label {
      color: #2c3558;
    }
    .selectOption {
      margin-left: ${({ optionLeftMargin }) =>
        optionLeftMargin ? optionLeftMargin : '8px'};
      font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
      &.colorGray {
        color: #9a9cb5;
      }
    }
  }

  .icon,
  .label--selectOption + svg {
    margin-left: 10px;
    font-size: 14px;
  }

  ${({ unable }) => {
    if (unable) {
      return css`
        opacity: 0.4;
        :hover {
          color: #2c3558;
          border-color: #d5d3e4;
          cursor: not-allowed;
        }
      `;
    }
  }}
`;

// background: '#F6F6FA',
// border: '1px solid #D5D3E4',
// '& svg *': {
//   fill: '#9A9CB5'

export const Gap = styled.div`
  height: 3px;
`;

export const SelectList = styled.div`
  position: absolute;
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 0px;
  max-height: min(600px, calc(100vh - 200px));
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c3558;
  z-index: 1060;
  transition: 0.3s;
  white-space: nowrap;
  overflow: overlay;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  @supports not (overflow: overlay) {
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d5d3e4;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    margin: 4px;
  }
  .search-input-wrap {
    position: relative;
    margin: 4px;
    padding: 3px 2px 3px 25px;
    width: calc(100% - 8px);
    border: 1px solid rgb(155, 81, 224);
    border-radius: 2px;
    svg {
      position: absolute;
      top: 3px;
      left: 4px;
    }
    input {
      border: 0 none;
    }
  }
`;

export const SelectFileList = styled.div`
  position: absolute;
  border: 1px solid #ececec;
  border-radius: 2px;
  /* padding: 4px; */
  max-height: min(600px, calc(100vh - 200px));
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c3558;
  z-index: 1060;
  transition: 0.3s;
  white-space: nowrap;
  overflow: overlay;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  /* gap: 4px; */

  .file-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    cursor: pointer;
    padding: 4px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
    :hover {
      svg {
        box-shadow: 0px 0px 4px 4px rgba(155, 81, 244, 0.4);
      }
    }
  }
`;
