import { LanguageCodeType } from '@src/common/config/Code';
import { usePageFunc } from '@src/hooks/utils';
import { TaskAmountInputs } from '../components/TaskAmountInputs.Detail.Comp';
import { cssTaskMakerItemDetail } from './JobDetail.style';
import { TPInputText } from '@src/units/input/TPInput.text.Detail';
import { cx } from '@emotion/css';
import {
  amountArraySortToObject,
  renderDateTimeNoWrap
} from '../dashboard/utils/render';
import { ITaskDetailSubInfo } from '@src/common/config/ITask';
import { Arrow } from '../../units/sobra/Arrow';
import { Worker } from '../../units/sobra/Worker';
import _ from 'lodash';
import { IconMessageOff, IconMessageOn } from '@src/resources/icon';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';

export interface LanguageTaskMakerItemProps {
  startDateTime: Date | undefined; //
  _rid?: string; //
  onDelete: () => void; //
  onMakeDisabled: (isDisabled: boolean) => void; //
  languageTaskTitle: string;
  isBoracatUse: boolean;
  tmMatch: boolean;
  taskAmount: { [key: string]: any };
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType;
  languageTaskAmountList: [any, any][];
  grossAmount: number;
  netAmount: number;
  endDateTime: Date | undefined;
  hopeSupplyDateTime: Date | undefined;
  pageMode?: string;
  languageSubTaskList?: ITaskDetailSubInfo[];
  taskType: string;
  // 수정일 경우 존재
  languageTaskStatus?: string;
  languageTaskKey?: string;
}

export function LanguageTaskMakerItem({
  languageTaskTitle,
  languageTaskAmountList,
  languageSubTaskList,
  isBoracatUse,
  tmMatch,
  taskType,
  taskAmount,
  grossAmount,
  netAmount,
  endDateTime,
  hopeSupplyDateTime,
  languageTaskStatus
}: LanguageTaskMakerItemProps) {
  const { taskAmountText } = usePageFunc();

  return (
    <div css={cssTaskMakerItemDetail}>
      <div
        className={cx('lengthCheck', {
          lengthError: languageTaskTitle.length > 100
        })}
      >
        <TPInputText
          label={'작업명'}
          name={'title'}
          value={languageTaskTitle}
        />
      </div>

      <div>
        <div className="title">작업 환경</div>
        <div className="content">
          <div className={cx('option', { active: isBoracatUse })}>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5224 3.46779L8.99018 2.94704L7.94868 2.01147L7.4809 2.53223L4.18465 6.20173L2.47938 4.59461L1.96996 4.11451L1.00977 5.13334L1.51918 5.61344L3.24882 7.24353L3.23777 7.25583L3.75212 7.71786L4.25562 8.19238L4.26697 8.18034L4.27927 8.19139L4.74139 7.67694L5.21582 7.17355L5.20378 7.1622L8.5224 3.46779Z"
                fill="#9B51E0"
              />
            </svg>
            보라캣
          </div>
          <div
            className={cx('option', {
              active: !isBoracatUse
            })}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5224 3.46779L8.99018 2.94704L7.94868 2.01147L7.4809 2.53223L4.18465 6.20173L2.47938 4.59461L1.96996 4.11451L1.00977 5.13334L1.51918 5.61344L3.24882 7.24353L3.23777 7.25583L3.75212 7.71786L4.25562 8.19238L4.26697 8.18034L4.27927 8.19139L4.74139 7.67694L5.21582 7.17355L5.20378 7.1622L8.5224 3.46779Z"
                fill="#9B51E0"
              />
            </svg>
            외부 CAT
          </div>
        </div>
      </div>
      <div className="totalRate">
        <div className="totalRateTitleRow">
          <div className="left">
            <div className="title">
              <div>총 분량</div>
            </div>
            {taskType !== 'LQA' && (
              <div className="grossAndNet">
                <div className="gross">
                  <span className="name">Gross</span>
                  <span className="value">
                    {grossAmount.toLocaleString('en')}
                  </span>
                </div>
                <div className="title">
                  <span className="name">Net</span>
                  <span className="value">
                    {netAmount.toLocaleString('en')}
                  </span>
                </div>
              </div>
            )}
          </div>
          {taskType === 'LQA' ? (
            <>(250단어 · 자 / 시간)</>
          ) : (
            <>
              {!isBoracatUse && (
                <div className="controls">
                  <div className="gross">
                    <span className="name tm">TM매치</span>
                    <span className={`value ${tmMatch ? 'on' : 'off'}`}>
                      {tmMatch ? 'ON' : 'OFF'}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {taskType === 'LQA' ? (
          <div className="time-calculation">
            {Math.floor(grossAmount / 250)}
            시간 {grossAmount % 250 > 0 ? '30' : '00'}분
          </div>
        ) : (
          <>
            {!isBoracatUse && (
              <div
                style={{
                  padding: '0 20px'
                }}
              >
                <TaskAmountInputs
                  taskAmount={{
                    ...amountArraySortToObject(languageTaskAmountList)
                  }}
                  tmMatch={tmMatch}
                  inputText={taskAmountText}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="dates">
        <TPInputText
          label={'마감일'}
          name={'endDateTime'}
          value={renderDateTimeNoWrap(
            endDateTime,
            !['COMPLETE', 'DELIVERY_COMPLETE'].includes(
              languageTaskStatus ?? ''
            )
          )}
        />
        <TPInputText
          label={'납품일'}
          name={'hopeSupplyDateTime'}
          value={renderDateTimeNoWrap(
            hopeSupplyDateTime,
            !['COMPLETE', 'DELIVERY_COMPLETE'].includes(
              languageTaskStatus ?? ''
            )
          )}
        />
      </div>
      {languageSubTaskList?.length ? (
        <div>
          <div className="title">
            <div className="left">작업 분량</div>
            <div className="right">
              {grossAmount !==
                _.reduce(
                  _.map(languageSubTaskList, (v: any) => v.taskAmount),
                  (sum, n) => sum + n,
                  0
                ) && (
                <div className="amountMismatch">
                  * 총 분량과 배분된 부작업의 총합이 불일치합니다.
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              gap: '10px'
            }}
          >
            {languageSubTaskList.map((v, i: number) => {
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%'
                  }}
                >
                  <div className="controls">
                    <div className="gross" style={{ minWidth: '32px' }}>
                      <span className="name">
                        {taskType === 'LQA'
                          ? `${Math.floor(v.taskAmount / 250)} 시간 ${
                              v.taskAmount % 250 > 0 ? '30' : '00'
                            } 분`
                          : v.taskAmount}
                      </span>
                    </div>
                  </div>
                  <div className="message-tooltip">
                    {v.memo === '' || v.memo === null ? (
                      <IconMessageOff width={14} height={14} />
                    ) : (
                      v.memo && (
                        <SimpleTooltip message={v.memo}>
                          <IconMessageOn width={14} height={14} />
                        </SimpleTooltip>
                      )
                    )}
                  </div>
                  <div className="workers">
                    {Array.isArray(v.workerList) && (
                      <>
                        <Worker
                          worker={v.workerList.find(
                            (e) => e.workerType === 'TRANSLATOR'
                          )}
                          type="번역"
                        />
                        <Arrow />
                        <Worker
                          worker={v.workerList.find(
                            (e) => e.workerType === 'FIRST_CHECKER'
                          )}
                          type="1차 검수"
                        />
                        <Arrow />
                        <Worker
                          worker={v.workerList.find(
                            (e) => e.workerType === 'SECOND_CHECKER'
                          )}
                          type="2차 검수"
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
